import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { StorageKeys, TradePositions } from '../constants';
import { Response } from '../core/interface/response';
import { LocalStorageService } from './local-storage.service';
import { PastPerformanceService } from './past-performance.service';
import { RestRequestorService } from './rest-requestor.service';

export interface ITradeReport {
  created_date: Date;
  date: Date;
  duration_days: number;
  entry_comment: string;
  entry_date: Date;
  entry_price: number;
  exit_comment: string;
  exit_date: Date;
  exit_price: number;
  id: number;
  position: TradePositions;
  profit: number;
  profit_loss_multiplier: number;
  shares_count: number;
  signal_end_id: number;
  signal_profit_loss_multiplier: number | null;
  signal_start_id: number;
  signal_stop_loss_amount: number | null;
  signal_target_profit_amount: number | null;
  stop_loss: number;
  stop_loss_amount: number;
  strategy_id: number;
  symbol: 'DDD';
  target_profit: number;
  target_profit_amount: number;
  total_profit: number;
  updated_date: Date | null;
}

@Injectable({
  providedIn: 'root',
})
export class TradeReportService {
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private pastPerformanceService: PastPerformanceService,
    private restRequestorService: RestRequestorService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  _get = async (security_id: number, strategy_id: number, date: string) => {
    const storageKey = `${StorageKeys.TradeReport}_${security_id}_${strategy_id}_${date ?? ''}`;

    const url = date
      ? `/v2/tradeReport?security_id=${security_id}&strategy_id=${strategy_id}&date=${date}`
      : `/v2/tradeReport?security_id=${security_id}&strategy_id=${strategy_id}`;

    const { result } = await this.restRequestorService.makeRequest(storageKey, () =>
      firstValueFrom(this.http.get<Response>(url)),
    );

    return result;
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get = async (security_id, strategy_id, index_by_entry_date = true, use_start_date = true) => {
    const pastPerformance = await this.pastPerformanceService.get(security_id, strategy_id);

    const date = use_start_date
      ? pastPerformance
        ? moment.utc(pastPerformance.date).format('YYYY-MM-DD')
        : moment().utc().format('YYYY-MM-DD')
      : null;

    // This key is used in ./app/coreV2/business/trading-chart/profit-loss.ts
    const storageKey = `${StorageKeys.TradeReport}_${security_id}_${strategy_id}_${index_by_entry_date}_${use_start_date}`;
    let tradeReport = this.localStorageService.getFromMemory(storageKey);

    if (!tradeReport) {
      const result = await this._get(security_id, strategy_id, date);

      const propertyForIndex = index_by_entry_date ? 'entry_date' : 'exit_date';

      tradeReport = {};
      for (let i = 0; i < result.length; i++) {
        const key = result[i][propertyForIndex];
        if (!key) {
          continue;
        }
        tradeReport[moment.utc(key).format('YYYY-MM-DD')] = {
          ...result[i],
          metadata: JSON.parse(result[i].metadata),
          bar_entry_date: moment.utc(result[i].entry_date).format('YYYY-MM-DD'),
          bar_exit_date: result[i].exit_date ? moment.utc(result[i].exit_date).format('YYYY-MM-DD') : null,
        };
      }
      this.localStorageService.setToMemory(storageKey, tradeReport);
    }

    return tradeReport;
  };

  getRecent: (security_id: number, strategy_id: number, position: TradePositions) => Promise<ITradeReport> = async (
    security_id,
    strategy_id,
    position,
  ) => {
    const tradeReport = await this.get(security_id, strategy_id);

    const trades = Object.values(tradeReport);
    for (let i = trades.length - 1; i >= 0; i--) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const trade: any = trades[i];
      if (trade.exit_date) {
        continue;
      }

      if (position === TradePositions.LongAndShort || trade.position === position) {
        return trade;
      }
    }

    return null;
  };
}
